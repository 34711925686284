import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import Template from '../../layouts/Template';
import { NowSection } from './_StyledNow';

const Now = ({ data }) => (
	<Template title={`Now | ${data.site.siteMetadata.title}`} desc="What I'm Doing Now...">
		<NowSection>
			<div className="wrapper">
				<h1>Now</h1>
				<div className="date">October 1st, 2019</div>
				<p>
					These days I'm into writing highly tested Typescript nodejs with a GraphQL api layer on a Serverless Lambda. The sheer
					types of ways you can trigger a Lambda via AWS events is exciting. And you can really build some cool applications
					quickly. You can also pretty much test drive it all.
				</p>
				<p>
					CloudFormation has grown on me, and I really find it enjoyable and productive to write. On the frontend, I'm finding
					Apollo clients super helpful with testing, caching, and just making GraphQL work. Tailwind is the styling framework of
					choice. And <a href="https://www.framer.com/motion/">Framer Motion</a> is how I prefer to style things.
				</p>
				<p>
					In terms of frontend architecture, it's crazy how much code you can remove by simply writing some custom hooks around
					queries and mutations.
				</p>
				<p>
					I'm currently working on a <a href="https://zeyventures.com">side project</a> as well.
				</p>
			</div>
		</NowSection>
		<NowSection>
			<div className="wrapper">
				<div className="date">Mar 1st, 2019</div>
				<p>
					I'm super into Serverless, Golang, and writing highly efficient code pushing the boundaries of the modern cloud. In
					terms of the frontend, I'm finding that Tailwind (or your hand-rolled version of that) is the only way to scale css. I'm
					finding testing to be much more interesting and helpful after breaking it down into three categories: unit test, visual
					regression tests, and end-to-end tests with Cypress.
				</p>
				<p>In Milwaukee and wanna meet up? Email me! tylerzey@gmail.com.</p>
			</div>
		</NowSection>
		<NowSection>
			<div className="wrapper">
				<div className="date">Nov 1st, 2018</div>
				<p>
					I’m at home in Oconomowoc with my wife and dogs. My current position is CTO for Easy Agent Pro. I'm writing a ton of
					React to create an amazing real estate software. I'm currently learning a lot about SSR, JS testing, and managing an
					ever-growing codebase.
				</p>
				<p>I like attending tech events in the Milwaukee area and talking about startups.</p>
			</div>
		</NowSection>
		<NowSection>
			<div className="wrapper">
				<div className="date">January, 2018</div>
				<p>
					I'm in the process of moving back home to Milwaukee, WI from Kansas City. After growing up in WI, I've been away for 10
					years. If you're in the area, <Link to="/contact">reach out</Link>! I'd love to meet up to talk tech/coffee/more.
				</p>
			</div>
		</NowSection>
		<NowSection>
			<div className="wrapper">
				<div className="date">Fall, 2017</div>
				<p>
					I'm working on the codebase of Easy Agent Pro. After building up the sales and marketing teams, the product requires
					most of my attention now. Our codebase was initially build on jQuery and Wordpress. We're moving towards a React based
					front-end with an Object Oriented PHP backend.
				</p>
			</div>
		</NowSection>
		<NowSection>
			<div className="wrapper">
				<div className="date">January, 2015</div>
				<p>
					I started Easy Agent Pro with my business partner after graduating with my masters degree in clarinet performance. Easy
					Agent Pro focused on providing great tools to help real estate agents.
				</p>
			</div>
		</NowSection>
	</Template>
);
Now.propTypes = {
	data: PropTypes.object.isRequired,
};
export default Now;

export const pageQuery = graphql`
	query NowQuery {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
