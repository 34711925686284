import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
	&.footer {
		${tw`container mx-auto bg-grey-lightest text-black text-center pt-32`};

		h3 {
			${tw`pb-24 text-16 sm:text-24`};
		}
		.heart {
			${tw`relative overflow-visible inline-block`};
			width: 26px;
			height: 27px;
			&__beat {
				${tw`absolute`};
				bottom: -5px;
				left: 0;
				transition: ease 0.2s;

				&:hover {
					animation: pulse 0.75s ease infinite;
				}
				@keyframes pulse {
					0%,
					50% {
						font-size: 1em;
					}
					20% {
						font-size: 1.1em;
					}
				}
			}
		}
	}
`;

const Copyright = styled.div`
	${tw`font-sans font-bold uppercase text-12 opacity-75 tracking-wide sm:text-16 pb-5`};
`;

const Year = new Date().getFullYear();

const Footer = () => (
	<StyledFooter className="footer">
		<h3>
			Designed with{' '}
			<span className="heart">
				<a href="https://sophiazey.com" rel="noopener noreferrer" className="heart__beat" target="_blank">
					♥
				</a>
			</span>{' '}
			by Sophia. Built by Tyler using Gatsby, React, and Tailwind
		</h3>
		<Copyright>&copy; {Year} Tyler Zey</Copyright>
	</StyledFooter>
);

export default Footer;
