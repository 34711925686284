import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';

import { ClosedX } from './StyledOpen';

const StyledNav = styled.nav`
	${tw`w-full mx-auto bg-primary m-auto`};
	position: fixed;
	z-index: 49;
	top: 0;
`;

const NavWrapper = styled.div`
	${tw`flex justify-between items-center pt-4 pb-4 pl-5 pr-5`};
	max-width: 1200px;
	margin: auto;
`;

const Logo = styled.div`
	${tw`text-24 text-white z-50 font-black font-sans tracking-wide`};
`;

const OpenSesame = styled.ul`
	position: fixed;
	${tw`ml-0 pl-0 bg-primary h-screen w-screen flex z-40 text-center list-reset flex-col justify-center`};

	animation: fade-in 0.5s ease;
	li {
		${tw`my-3`};
	}

	@keyframes fade-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`;

const Styledlink = styled(Link)`
	${tw`font-sans text-32 sm:text-48 text-white  no-underline`};
	&:hover {
		${tw`underline`};
	}
`;
const StyledA = styled.a`
	${tw`font-sans text-32 sm:text-48 text-white no-underline`};
	&:hover {
		${tw`underline`};
	}
`;

export default class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
		this.go = this.go.bind(this);
	}

	go() {
		this.setState({ open: false });
	}

	render() {
		const { open } = this.state;

		return (
			<React.Fragment>
				<StyledNav>
					<NavWrapper>
						<Logo>
							<Link to="/">TYLER ZEY</Link>
						</Logo>
						<div>
							<ClosedX aria-label="Open and close the menu" type="button" onClick={() => this.setState({ open: !open })}>
								<div className={`hamburger ${open ? 'change' : ''}`}>
									<div className="bar-1" />
									<div className="bar-2" />
									<div className="bar-3" />
								</div>
							</ClosedX>
						</div>
					</NavWrapper>
				</StyledNav>
				{open && (
					<OpenSesame>
						<li>
							<Styledlink onClick={this.go} to="/">
								Home
							</Styledlink>
						</li>
						<li>
							<Styledlink onClick={this.go} to="/now/">
								Now
							</Styledlink>
						</li>
						<li>
							<Styledlink onClick={this.go} to="/about/">
								About
							</Styledlink>
						</li>
						<li>
							<Styledlink onClick={this.go} to="/blog/">
								Blog
							</Styledlink>
						</li>
						<li>
							<StyledA onClick={this.go} href="https://www.linkedin.com/in/tylerzey/" target="_blank" rel="noopener noreferrer">
								LinkedIn
							</StyledA>
						</li>
						<li>
							<Styledlink onClick={this.go} to="/contact/">
								Contact
							</Styledlink>
						</li>
					</OpenSesame>
				)}
			</React.Fragment>
		);
	}
}
