import styled from 'styled-components';

export const ClosedX = styled.button`
	&:focus {
		.hamburger {
			outline: 5px auto -webkit-focus-ring-color;
		}
	}
	.hamburger {
		${tw`z-50 cursor-pointer`};
		.bar-1,
		.bar-2,
		.bar-3 {
			${tw`bg-grey-light`};
		}
	}
	.bar-1,
	.bar-2,
	.bar-3 {
		width: 45px;
		height: 2px;
		background: #fff;
		margin: 0.75rem 0;
		transition: ease all 0.2s;
	}

	.change {
		.bar-1 {
			-webkit-transform: rotate(-45deg) translate(-7px, 10px);
			transform: rotate(-45deg) translate(-7px, 10px);
		}
		.bar-2 {
			opacity: 0;
		}
		.bar-3 {
			-webkit-transform: rotate(45deg) translate(-10px, -12px);
			transform: rotate(45deg) translate(-10px, -12px);
		}
	}
`;
