import styled from 'styled-components';

export const MainStyles = styled.div`
	${tw`font-serif`};

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	a,
	div,
	p {
		::selection {
			${tw`bg-secondary text-black`};
		}
	}

	h1,
	h2,
	h3,
	h4 {
		${tw`font-normal`};
	}

	h1 {
		${tw`text-32 sm:text-64 tracking-medium`};
	}

	h2 {
		${tw`text-16 sm:text-32 tracking-medium`};
	}

	p {
		${tw`text-14 sm:text-24 mb-4`};
	}

	.date,
	h5 {
		${tw`text-12 sm:text-16 font-sans opacity-75 tracking-wide `};
		text-transform: uppercase;
	}
	section {
		${tw`px-7 min-h-90`};
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: 112px;
		padding-bottom: 112px;

		&:nth-of-type(odd) {
			${tw`bg-primary text-white`};
		}
	}
	footer {
		${tw`px-7`};
	}

	.container {
		${tw`w-100 m-auto pl-5 pr-5`};
		@media (min-width: 1200px) {
			max-width: 1200px;
		}
	}

	.button {
		${tw`inline-block text-12 sm:text-16 md:text-20 font-sans tracking-wide uppercase font-bold bg-primary mt-12 px-8 py-4 text-white`};
	}

	.wrapper {
		${tw`mx-auto w-full`};
		max-width: 996px;
	}

	.underline {
		${tw`pb-6 mb-10 md:max-w-3/4 xl:max-w-1/2 `};
		// border-bottom: 2px solid #04364c;
		&__wrapper {
			${tw`pl-5`};
			@media (min-width: 1200px) {
				${tw`ml-auto `};
				max-width: 600px;
			}
		}
		&__title {
			${tw`text-48 inline relative`};
			@media (min-width: 1200px) {
				&:after {
					${tw`absolute bg-white`};
					content: '';
					width: 200px;
					height: 10px;
					bottom: -1.5rem;
				}
			}
		}
	}
	section.blog {
		${tw`bg-white text-black pt-32 px-0`};

		.title {
			${tw`mb-8 xl:max-w-3/4`};
		}
		blockquote {
			margin-left: 2em;
			font-style: italic;
			${tw`text-grey-dark`}
		}
	}
`;
