import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import Footer from '../components/Footer';
import Header from '../components/Header';

import './styles.css';

import { MainStyles } from './styles';

const Template = (props) => {
	const { desc, title, img, children } = props;

	return (
		<MainStyles>
			<Helmet>
				<html lang="en" />
				<link rel="icon" type="image/png" href="/tylerzey.png" sizes="16x16" />
				<title>{title}</title>
				<meta name="description" content={desc} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={desc} />
				<meta property="og:image" content={img} />
				<meta itemProp="og:headline" content={title} />
				<meta property="og:type" content="website" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:description" content={desc} />
				<meta name="twitter:site" content="@tylerzey" />
				<meta name="twitter:creator" content="@tylerzey" />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:image" content={img} />
			</Helmet>
			<Header />
			{children}
			<Footer />
		</MainStyles>
	);
};

Template.propTypes = {
	desc: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	img: PropTypes.string,
	children: PropTypes.node.isRequired,
};
/**
 * @todo = replace this
 */
Template.defaultProps = {
	img: '/tylerzey.png',
};

export default Template;
