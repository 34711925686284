import styled from 'styled-components';

export const NowSection = styled.section`
	position: relative;

	&::after {
		content: '';
		width: 36px;
		height: 17px;
		position: absolute;
		bottom: -16px;
		z-index: 1;
		left: calc(50% - 18px);
		border-left: 18px solid transparent;
		border-right: 18px solid transparent;
		border-top: 13px solid #072f40;
	}
	&:nth-of-type(even) {
		&::after {
			border-top: 13px solid #fff;
		}
	}

	.date {
		${tw`mb-8 text-20`};
	}
`;
